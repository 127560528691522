<template>
    <section class="main-content-section">
        <div class="grid grid-pad">
            <div class="col-1-1">
                <h2 class="page-title">{{productCatalog}}</h2>
            </div>
        </div>

        <LoadingSpinner v-if="isLoading" />

        <div v-else class="grid grid-pad products-container">
            <ProductCatalogItem 
                v-for="product in products" :key="product.id"
                :product="product" 
                :order="order"
                @showModal="showNoItemsSelectedWarning($event)"
                @saveOrderFailed="showModal($event)"
            />
        </div>

        <WarningMessageModal 
            v-show="showWarningModal" 
            @closeModal="closeModal()"
            :title="errorTitle"
            :text="errorMessage" 
        />
    </section>
</template>

<script>
import ProductCatalogItem from '@/components/ProductCatalogItem.vue'
import WarningMessageModal from '@/components/modals/WarningMessageModal.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'ProductCatalog',
    components: { ProductCatalogItem, WarningMessageModal, LoadingSpinner },

    data() {
        return {
            showWarningModal: false,
            errorTitle: '',
            errorMessage: ''
        }
    },

    computed: {
        ...mapGetters([
            'isLoading', 
            'metaTitle',
            // Texts
            'productCatalog',
            'youHaveNotSelectedAnyProducts',
            'addAtLeastOneItemToYourShoppingList'
        ]),
        ...mapGetters({
            products: 'getProducts',
            order: 'getOrder',
        }),
    },

    methods: {
        setDocumentTitle() {
            document.title = this.productCatalog + this.metaTitle;
        },

        showNoItemsSelectedWarning(e) {
            this.errorTitle = this.youHaveNotSelectedAnyProducts;
            this.errorMessage = this.addAtLeastOneItemToYourShoppingList;
            this.showWarningModal = e;
        },

        showModal(e) {
            this.errorTitle = e.title;
            this.errorMessage = e.detail
            this.showWarningModal = true;
        },

        closeModal() {
            this.errorTitle = '';
            this.errorMessage = '';
            this.showWarningModal = false;
        },
    },

    created() {
        this.setDocumentTitle();

        if (this.products === null) {
            this.$store.dispatch('fetchProducts');
        }
        
        if (this.$route.query.orderId) {
            this.$store.dispatch('fetchOrder', this.$route.query.orderId);
        }
    },

    updated() {
        this.setDocumentTitle();
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.products-container {
    margin-bottom: 4rem;
}
</style>